import * as style from '../styles/menuIcon.module.scss';
import React from 'react';

export default function MenuIcon() {
  return (
    <div className={style.menuIcon}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
