import Contatcs from './shared/Contatcs';
import Adress from './shared/Adress';
import WebMap from './shared/WebMap';
import Logo from './shared/Logo';
import * as style from '../styles/footer.module.scss';
import React from 'react';
import classnames from 'classnames';
import { commonlocalesData } from '../data/commonlocales.data';
import SocialIcons from './SocialIcons';

export default function Footer({ color = '' }) {
  const locales = commonlocalesData();
  return (
    <section className={classnames(style.footerSection, style[color])}>
      <div className={style.center}>
        <div className={style.col1}>
          <div className={style.logo}>
            <Logo color={color}></Logo>
          </div>
          <div className={style.contactUsText}>
            {locales?.kontaktujteNasFooter?.split(';').map((footerNadpis: any, i: number) => (
              <span key={i}>{footerNadpis}</span>
            ))}
          </div>
          <SocialIcons />
          <Contatcs color={color}></Contatcs>
          <Adress color={color}></Adress>
        </div>
        <div className={style.colMenu}>
          <h1>{locales.mapawebovychstranek}</h1>
          <WebMap></WebMap>
          <div className={style.madeByNetworg}>
            Proudly powered by{' '}
            <a href="https://www.networg.com/" target="_blank">
              <img src="/assets/logo/logo_networg_white.png" width="140px" />
              <img
                src="/assets/logo/tm.svg"
                style={{
                  width: 15,
                  filter: 'invert(1)',
                  position: 'absolute',
                  right: -20,
                  bottom: 6,
                }}
                alt="Trademark"
              />
            </a>
          </div>
          <nav className={style.bottomMenu}>
            <a href={locales?.obchodnipodminky?.mediaItemUrl} target="_blank">
              {locales?.obchodnipodminkyText}
            </a>
            <a href={locales?.osobniudajefile.mediaItemUrl} target="_blank">
              {locales?.osobniudajeText}
            </a>
            <a href="/about/download">{locales?.kestazeni}</a>
          </nav>
        </div>
      </div>
    </section>
  );
}
