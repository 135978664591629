// extracted by mini-css-extract-plugin
export var bottomMenu = "footer-module--bottomMenu--LATvL";
export var center = "footer-module--center--61KgZ";
export var col1 = "footer-module--col1--Upg89";
export var colMenu = "footer-module--colMenu--qzFgL";
export var contactUsText = "footer-module--contactUsText--cBg6I";
export var copyright = "footer-module--copyright--kPsoG";
export var footerSection = "footer-module--footerSection--4eJiJ";
export var logo = "footer-module--logo--x0d01";
export var madeByNetworg = "footer-module--madeByNetworg--SGiWK";
export var orange = "footer-module--orange--pH8Eu";