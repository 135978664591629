import * as style from '../../styles/shared/contacts.module.scss';
import React from 'react';
import classnames from 'classnames';
import { talxisCompanyData } from '../../data/talxis.data';

interface Props {
  direction?: 'row' | 'column';
  color?: string;
}
export default function Contacts({ direction, color = 'green' }: Props) {
  const data = talxisCompanyData();
  return (
    <div
      style={{ flexDirection: direction || 'row' }}
      className={classnames(style.contactUs, style[color])}
    >
      <a href={'tel:' + data.telefonnKontakt}>
        <img src="/assets/icons/telephone.svg" alt="phone icon" /> {data.telefonnKontakt}
      </a>
      <a href={'mailto:' + data.email}>
        <img src="/assets/icons/email.svg" alt="email icon" /> {data.email}
      </a>
    </div>
  );
}
