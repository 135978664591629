import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useTalxisMetadataCs } from '../hooks/cs/footer-metadata';

export function talxisCompanyData(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = useTalxisMetadataCs();
  } else {
    data = useTalxisMetadataCs();
  }
  return data;
}
