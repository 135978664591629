import React from 'react';

export interface Props {
  bigLogo?: boolean;
  color?: string;
}
interface State {
  logoVariations: { color: string; logo: string }[];
}
export default class Head extends React.Component<Props> {
  state: State = {
    logoVariations: [
      { color: 'orange', logo: '/assets/logo/logo_orange.png' },
      { color: 'default', logo: '/assets/logo/logo_purple.png' },
      { color: 'purple', logo: '/assets/logo/logo_purple.png' },
      { color: 'green', logo: '/assets/logo/logo_green.png' },
      { color: 'darkorange', logo: '/assets/logo/logo_darkorange.png' },
    ],
  };

  constructor({ color = 'default', bigLogo }: Props) {
    super({ color, bigLogo });
  }

  get getLogo() {
    const logo = this.state.logoVariations.find(({ color }) => color === this.props.color) || null;
    return logo ? logo.logo : '/assets/logo/logo_purple.png';
  }

  render() {
    return (
      <div style={{ position: 'relative', width: this.props.bigLogo ? '100%' : '100%' }}>
        <img src={this.getLogo} style={{ width: '100%' }} alt="Talxis logo" />
        <img
          src="/assets/logo/tm.svg"
          style={{ width: 15, filter: 'invert(1)', position: 'absolute', right: -20 }}
          alt="Trademark"
        />
      </div>
    );
  }
}
