import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import MenuIcon from './MenuIcon';
import LanguagePicker from './LanguagePicker';
import Menu from './Menu';
import Logo from './shared/Logo';
import * as headStyle from '../styles/head.module.scss';
import WebMap from './shared/WebMap';

export interface Props {
  bigLogo?: boolean;
  color?: string;
  fixed?: boolean;
}

const Head: React.FC<Props> = ({ bigLogo, color, fixed }) => {
  const [showFixedMenu, setShowFixedMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenu = () => {
    setShowMenu(true);
  };

  const handleHideMenu = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      // This will make the menu appear when user scrolls more than 100px, adjust as needed
      if (window.scrollY > 500) {
        setShowFixedMenu(true);
      } else {
        setShowFixedMenu(false);
      }
    };

    // Attach scroll listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup runs on unmount

  return (
    <div
      className={classnames(
        !fixed ? headStyle.headSection_fixed : null,
        !fixed ? headStyle[color ?? 'purple'] : null,
        showFixedMenu ? headStyle['show'] : '',
      )}
    >
      <Menu show={showMenu} handleClose={handleHideMenu}></Menu>
      <section className={classnames(fixed ? headStyle.head : headStyle.head_fixed)}>
        <div className={headStyle.logo}>
          <Link to="/">
            <Logo bigLogo={bigLogo} color={color}></Logo>
          </Link>
          <div className={headStyle.logo_perex}>
            Manažerský informační systém pro automatizaci a digitalizaci firemních procesů,
            postavený nad Microsoft Power Platform
          </div>
        </div>
        <div className={headStyle.menu_desktop}>
          <WebMap direction="complete_row" showHome={false}></WebMap>
        </div>
        <div className={headStyle.options}>
          <div className={headStyle.separator}></div>
          {/* <div className={headStyle.languageSelector}>
            <LanguagePicker></LanguagePicker>
          </div> */}

          <div className={headStyle.separator}></div>
          <div
            className={classnames(headStyle.menuButton, headStyle.menu_mobile)}
            onClick={handleShowMenu}
          >
            <MenuIcon />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Head;
