// extracted by mini-css-extract-plugin
export var blue = "button-module--blue--2UuI5";
export var button = "button-module--button--jo7SM";
export var darkOrange = "button-module--darkOrange--eq1FJ";
export var darkorange = "button-module--darkorange--fqNi9";
export var darkpurple = "button-module--darkpurple--HT-7b";
export var green = "button-module--green--Q50AS";
export var noHover = "button-module--noHover--BMKfH";
export var orange = "button-module--orange--KvuN7";
export var outline = "button-module--outline--QD63T";
export var purple = "button-module--purple--uHrTl";
export var right = "button-module--right--H+XZ-";