import { useStaticQuery, graphql } from 'gatsby';

// EN_ID - cG9zdDoyMDg=
export const useTalxisMetadataCs = () => {
  const data = useStaticQuery(graphql`
    query Talxis {
      wpTalxis(id: { eq: "cG9zdDo1NA==" }) {
        id
        companyName
        companyAddress
        instagramURL
        email
        jazyk
        telefonnKontakt
        facebookURL
      }
    }
  `);

  return data.wpTalxis;
};
