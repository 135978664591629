import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useLocalesMetadata } from '../hooks/cs/locales-metadata';
import { useLocalesMetadataEn } from '../hooks/en/locales-metadata.en';

export function commonlocalesData(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = useLocalesMetadata();
  } else {
    data = useLocalesMetadataEn();
  }
  return data;
}
