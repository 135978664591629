// extracted by mini-css-extract-plugin
export var big = "head-module--big---XbB-";
export var blue = "head-module--blue--0HIGO";
export var darkorange = "head-module--darkorange--d--5m";
export var green = "head-module--green--fiFAe";
export var head = "head-module--head--AkslI";
export var headSection_fixed = "head-module--headSection_fixed--3CDKs";
export var head_fixed = "head-module--head_fixed--ERJpx";
export var languageSelector = "head-module--languageSelector--PoK7E";
export var lastEdit = "head-module--lastEdit--hx-na";
export var logo = "head-module--logo--Bgos9";
export var logo_perex = "head-module--logo_perex--kYZyG";
export var menuButton = "head-module--menuButton--kVWbU";
export var menu_desktop = "head-module--menu_desktop--teiYu";
export var menu_mobile = "head-module--menu_mobile--DGBZQ";
export var options = "head-module--options--aKWHj";
export var orange = "head-module--orange--5s7ZJ";
export var purple = "head-module--purple--E5d77";
export var separator = "head-module--separator--BY8fw";
export var show = "head-module--show--qDuc7";
export var tryForFree = "head-module--tryForFree--hQHXC";
export var tryForFreeMobile = "head-module--tryForFreeMobile--Vq4BF";