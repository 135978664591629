import * as style from '../styles/socialIcons.module.scss';

import React from 'react';
import { talxisCompanyData } from '../data/talxis.data';

export default function SocialIcons() {
  const node = talxisCompanyData();
  return (
    <div className={style.social}>
      <div>
        <a
          className={style.iconCyrcle}
          target="_blank"
          href="https://www.linkedin.com/company/networg/"
        >
          <img src="/assets/icons/linkedin.png" alt="LinkedIn" />
        </a>
      </div>
      <div className={style.icon}>
        <a target="_blank" href={node.facebookURL}>
          <img src="/assets/icons/facebook.svg" alt="" />
        </a>
      </div>
      <div className={style.icon}>
        <a target="_blank" href={node.instagramURL}>
          <img src="/assets/icons/instagram.svg" alt="" />
        </a>
      </div>
    </div>
  );
}
