import { useStaticQuery, graphql } from 'gatsby';

export const useLocalesMetadata = () => {
  const data = useStaticQuery(graphql`
    query CommonLocalesCs {
      wpObecnPEklady(id: { eq: "cG9zdDo2NzE=" }) {
        id
        jazyk
        osobniudajeText
        obchodnipodminkyText
        vyzkouetZdarma
        kontaktujteNasFooter
        mapawebovychstranek
        kestazeni
        menuDokumentace
        menuCeny
        menuDokumentaceLink
        menuPodpora
        menuPodporaLink
        menuProdukty
        zobrazitvice
        zobrazitmene
        obchodnipodminky {
          mediaItemUrl
        }
        osobniudajefile {
          mediaItemUrl
        }
      }
    }
  `);

  return data.wpObecnPEklady;
};
