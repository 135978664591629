import { useStaticQuery, graphql } from 'gatsby';

export const useLocalesMetadataEn = () => {
  const data = useStaticQuery(graphql`
    query CommonLocalesEn {
      wpObecnPEklady(id: { eq: "cG9zdDoxMDIz" }) {
        id
        jazyk
        osobniudajeText
        obchodnipodminkyText
        vyzkouetZdarma
        kestazeni
        kontaktujteNasFooter
        mapawebovychstranek
        menuDokumentace
        menuCeny
        menuDokumentaceLink
        menuPodpora
        menuPodporaLink
        menuProdukty
        zobrazitvice
        zobrazitmene
        obchodnipodminky {
          mediaItemUrl
        }
        osobniudajefile {
          mediaItemUrl
        }
      }
    }
  `);
  console.log('data');
  console.log(data);
  return data.wpObecnPEklady;
};
