import * as style from '../../styles/shared/adress.module.scss';

import React from 'react';
import classnames from 'classnames';
import { talxisCompanyData } from '../../data/talxis.data';

export default function Adress({ color = 'green' }) {
  const data = talxisCompanyData();

  return (
    <div className={classnames(style.copyright, style[color])}>
      <span>{data.companyName}</span> – {data.companyAddress}
    </div>
  );
}
