import * as style from '../../styles/shared/webMap.module.scss';
import React from 'react';
import { Link } from 'gatsby';
import * as buttonStyle from '../../styles/button.module.scss';
import { commonlocalesData } from '../../data/commonlocales.data';

interface Props {
  direction?: 'row' | 'column' | 'complete_row';
  showHome?: boolean;
}

export default function WebMap(props: Props) {
  const data = commonlocalesData();

  const gridTemplate = (): string => {
    if (props.direction === 'column') {
      return '1fr';
    } else if (props.direction === 'row') {
      return '1fr 1fr';
    } else if (props.direction === 'complete_row') {
      return 'unset';
    } else {
      return '';
    }
  };

  return (
    <nav
      style={{
        gridTemplateColumns: gridTemplate(),
        gridAutoFlow: props.direction === 'complete_row' ? 'column' : '',
        gap: props.direction === 'complete_row' ? '7px' : '',
        fontSize: props.direction === 'complete_row' ? '1rem' : '',
        minHeight: props.direction === 'complete_row' ? 'unset' : '',
      }}
      className={style.menu}
    >
      <Link
        style={{
          display: props.showHome ? 'block' : 'none',
        }}
        to="/"
        className={buttonStyle.noHover}
      >
        <img src="/assets/icons/home.svg" alt="" />
      </Link>
      <Link to="/products" className={buttonStyle.noHover}>
        {data.menuProdukty}
      </Link>
      <Link className={buttonStyle.noHover} to="/services">
        Služby
      </Link>
      <Link className={buttonStyle.noHover} to="/pricing">
        {data.menuCeny}
      </Link>
      <a className={buttonStyle.noHover} href={data.menuPodporaLink} target="blank">
        {data.menuPodpora}
      </a>
      <Link className={buttonStyle.noHover} to="/faq">
        Časté dotazy
      </Link>
      <a className={buttonStyle.noHover} href={data.menuDokumentaceLink} target="blank">
        {data.menuDokumentace}
      </a>
      {/* <Link className={buttonStyle.noHover} to="/">KDE NAKUPOVAT?</Link> */}

      {/* <Link className={buttonStyle.noHover} to="/">PRO OBCHODNÍ PARTNERY</Link> */}

      {/* <Link className={buttonStyle.noHover} to="/">O NÁS</Link> */}
    </nav>
  );
}
